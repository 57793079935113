// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-list-js": () => import("./../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-pages-404-mdx": () => import("./../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-blog-inspiration-mdx": () => import("./../src/pages/blog-inspiration.mdx" /* webpackChunkName: "component---src-pages-blog-inspiration-mdx" */),
  "component---src-pages-wish-list-mdx": () => import("./../src/pages/wish-list.mdx" /* webpackChunkName: "component---src-pages-wish-list-mdx" */),
  "component---src-pages-contact-mdx": () => import("./../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */)
}

